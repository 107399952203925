'use client'

import { useEffect } from 'react'

function PlausibleAnalytics() {
	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.plausible =
				window.plausible ||
				function () {
					;(window.plausible.q = window.plausible.q || []).push(arguments)
				}
		}
	}, [])

	return null
}

export default PlausibleAnalytics
